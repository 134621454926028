import * as React from 'react';
import WeDash from '../../assets/img/logo.svg';
import WhiteWeDash from '../../assets/img/white_logo.svg';
import { Footer, Header } from '../../components';
import { headerOptions } from './mockProps';
import * as S from './styled';

const Terms = () => {
  return (
    <>
      <Header
        logo={WeDash}
        navLinks={headerOptions}
        navButton="https://app.wedash.digital/"
        contactsPage
      />
      <S.Container>
        <h1>PARA VOCÊ ENTENDER MELHOR COMO FUNCIONA ESTA PLATAFORMA</h1>
        <p>
          Fizemos o máximo para reduzir e simplificar as regras da nossa
          plataforma. Por isso, separamos abaixo os pontos mais importantes para
          você, que também podem ser lidos de forma bem completa e detalhada nos
          Termos de Uso a seguir.
        </p>
        <p>
          Além disso, estamos sempre disponíveis para tirar qualquer dúvida que
          você tenha por meio do e-mail{' '}
          <a href="mailto:contato@wedash.digital">contato@wedash.digital</a>.
        </p>
        <ol>
          <li>
            <h3>O que fazemos? </h3>
            <p>
              Nosso negócio é uma plataforma de dashboard com integração dos
              dados do desempenho de marketing e vendas dos canais digitais dos
              Usuários, para maior performance nos negócios, o que permite a
              visualização de dados de várias plataformas distintas ao mesmo
              tempo.
            </p>
          </li>

          <li>
            <h3>O uso da nossa plataforma é pago?</h3>
            <p>
              Os Usuários deverão contratar os serviços do{' '}
              <strong>Wedash</strong>, realizando o pagamento referente ao plano
              que escolher, os valores estarão sempre expostos antes da
              contratação. A cobrança ocorre no formato de assinatura, podendo
              ser mensal ou anual. O plano será renovado automaticamente ao
              chegar ao fim do período contratado e o Usuário poderá cancelar o
              plano a qualquer momento antes da próxima cobrança.
            </p>
          </li>

          <li>
            <h3>É seguro usar a nossa plataforma?</h3>
            <p>
              Nos esforçamos bastante para que a nossa plataforma seja segura,
              mas ainda assim recomendamos que, antes de baixar qualquer
              conteúdo, você instale antivírus e programas de proteção.
            </p>
          </li>

          <li>
            <h3>Estas condições podem ser alteradas?</h3>
            <p>
              Nossos Termos de Uso poderão mudar, mas você sempre poderá acessar
              a versão mais atualizada na nossa plataforma. Além disso, se
              formos realizar alguma ação que a lei exija sua autorização, você
              receberá um aviso antes para poder aceitar ou recusar.
            </p>
          </li>

          <li>
            <h3>O que acontece com os dados pessoais?</h3>
            <p>
              Temos uma Política de Privacidade que trata sobre o que fazemos
              com os seus dados pessoais. É muito importante que você leia e
              entenda esse documento também!{' '}
            </p>
          </li>

          <li>
            <h3>Qual é o conteúdo dos Termos de Uso?</h3>
            <p>
              Os Termos de Uso a seguir estão divididos da seguinte forma para
              facilitar o seu acesso à informação:
            </p>

            <p>a)Data de Disponibilização do Texto;</p>
            <p>b) Explicação dos Termos Técnicos ou em Língua Estrangeira;</p>
            <p>c) Serviços;</p>
            <p>d) Cadastro dos Usuários;</p>
            <p>e) Pagamento pelo Uso da Plataforma;</p>
            <p>f) Responsabilidades das Partes;</p>
            <p>g) Isenção de Responsabilidade do Wedash;</p>
            <p>h) Regras de Conduta e Proibições;</p>
            <p>i) Propriedade Intelectual;</p>
            <p>j) Tratamento de Dados Pessoais, Privacidade e Segurança;</p>
            <p>k) Desdobramentos do Acesso à Plataforma;</p>
            <p>l) Alterações dos Termos e Condições de Uso;</p>
            <p>m) Definição da Lei Aplicável e do Foro de Eleição;</p>
            <p>n) Canal de Contato.</p>
          </li>
        </ol>
        <h2>TERMOS DE USO</h2>
        <p>
          Esta plataforma, cujo nome é <strong>Wedash</strong>, é de
          propriedade, mantida e operada por <strong>BOWE LTDA</strong>, pessoa
          jurídica constituída na forma de sociedade empresária Limitada,
          inscrita no CNPJ sob o nº 19.463.933/0001-43, com sede na Avenida
          Belarmino Cotta Pacheco, nº 08, Sala 01, Bairro Santa Mônica, CEP:
          38.408-168, na cidade de Uberlândia, estado de Minas Gerais.
        </p>
        <p>
          <strong>
            Este documento visa prestar informações sobre o modo de utilização
            da plataforma pelos Usuários e suas ferramentas, condições,
            nomenclaturas, direitos e deveres, além de preservar a privacidade
            dos Usuários.
          </strong>
          Alertamos que todo o texto deve ser lido com atenção e, caso você não
          concorde com o conteúdo de nossos termos e/ou política de privacidade,
          não dê prosseguimento à navegação ou a utilização de nossos serviços.
          Recomendamos, ainda, que caso seja aceito, que você armazene ou
          imprima uma cópia deste contrato, incluindo todas as políticas.
        </p>

        <ol>
          <li>
            <h3>DATA DE DISPONIBILIZAÇÃO DO TEXTO</h3>
            <ol>
              <li>
                A presente versão deste documento foi disponibilizada em:
                01/11/2021.
              </li>
            </ol>
          </li>

          <li>
            <h3>EXPLICAÇÃO DOS TERMOS TÉCNICOS OU EM LÍNGUA ESTRANGEIRA</h3>
            <ol>
              <li>
                Abaixo estão dispostos os significados das nomenclaturas
                técnicas e termos na língua inglesa.
              </li>

              <ul>
                <li>
                  Dashboard: é um painel visual que contém informações, métricas
                  e indicadores da empresa.
                </li>
                <li>
                  Login: É o processo que permite o acesso a um sistema
                  informático, controlado por meio de identificação e
                  autenticação do Usuário pelas credenciais fornecidas por esse
                  mesmo internauta.
                </li>
                <li>
                  Online: Termo da língua inglesa cujo significado literal é “em
                  linha”. É habitualmente usado para designar que um determinado
                  Usuário da internet ou de outra rede de computadores está
                  conectado à rede.
                </li>
                <li>
                  Vírus: Software malicioso que infecta sistemas e computadores,
                  com objetivo de causar algum dano, faz cópias de si mesmo e
                  tenta se espalhar para outros computadores, utilizando-se de
                  diversos meios.
                </li>
              </ul>

              <li>
                São os tipos de Usuários da plataforma:
                <ul>
                  <li>
                    Usuário Administrador: Aquele que administrará a plataforma
                    atuando em nome do <strong>Wedash</strong>.
                  </li>
                  <li>
                    Usuário Cliente: Aquele que adquire os produtos e serviços
                    do <strong>Wedash</strong>, por meio do plano escolhido,
                    podendo ainda, avaliar os produtos e serviços oferecidos.
                  </li>
                  <li>
                    Estes Usuários, em conjunto, serão chamados apenas de
                    Usuários.
                  </li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            <h3>SERVIÇOS</h3>
            <ol>
              <li>
                <strong>O que somos:</strong> O <strong>Wedash</strong>{' '}
                consiste, mas não se limita a uma plataforma de dashboard com
                integração dos dados do desempenho de marketing e vendas dos
                canais digitais dos Usuários, para maior performance nos
                negócios, o que permite a visualização de dados de várias
                plataformas distintas ao mesmo tempo.
              </li>
              <li>
                Ao utilizar o <strong>Wedash</strong>, o Usuário deve
                compreender e aceitar que todas as suas ações e escolhas são
                livres e não possuem nenhum tipo de influência ou ingerência do{' '}
                <strong>Wedash</strong>.
              </li>
              <li>
                Os Usuários poderão fazer avaliações da plataforma{' '}
                <strong>Wedash</strong>, atribuindo notas de 1 (uma) a 10 (dez)
                e comentários, a fim de demonstrar suas satisfações, reclamações
                e opiniões. O Usuário emitente da avaliação será o único a
                responder por sua manifestação. Avaliações que sejam julgadas
                pelo <strong>Wedash</strong> como impróprias poderão ser
                excluídas, sem a necessidade de notificação prévia.
              </li>
              <li>
                Fica esclarecido que o presente serviço não estabelece entre as
                partes qualquer vínculo empregatício, societário ou associativo,
                permanecendo cada parte como única responsável por todas as suas
                respectivas despesas e encargos, sejam de natureza trabalhista,
                previdenciária, fiscal, securitária, civil, penal ou de qualquer
                outra natureza ou espécie.
              </li>
            </ol>
          </li>

          <li>
            <h3>CADASTRO DOS USUÁRIOS</h3>

            <ol>
              <li>
                Para o cadastro e utilização dos serviços do Wedash serão
                requeridas as seguintes informações:{' '}
                <ol>
                  <li>Para Usuário Administrador: Nome e e-mail.</li>
                  <li>
                    Para Usuário Cliente: Razão Social, CNPJ, nome completo do
                    responsável e e-mail.
                  </li>
                </ol>
              </li>

              <li>
                A conta com login e senha de acesso do Usuário será criada pelo
                backoffice do <strong>Wedash</strong>, e será encaminhado para o
                e-mail do responsável, cadastrado na plataforma, sendo de sua
                exclusiva responsabilidade a manutenção do sigilo dessas
                informações. O<strong>Wedash</strong> não se responsabiliza
                pelas ações e danos que poderão ser causados pelo acesso
                irregular da conta de acesso por terceiros.
              </li>

              <li>É permitida a realização de apenas uma conta por Usuário.</li>
              <li>
                O cadastro na plataforma é permitido{' '}
                <strong>somente a pessoas acima de 18 (dezoito)</strong> anos e
                o <strong>Wedash</strong> fará esforços razoáveis para confirmar
                esse dado, mas não se responsabiliza caso a pessoa cadastrada
                não possua a idade mínima aqui descrita.{' '}
              </li>

              <li>
                Os Usuários são responsáveis por fornecer informações
                verdadeiras, precisas e atualizadas.
              </li>

              <li>
                O <strong>Wedash</strong> poderá recusar, suspender ou cancelar
                sem notificação prévia a conta de acesso de um Usuário sempre
                que suspeitar que as informações fornecidas são falsas,
                incompletas, desatualizadas ou imprecisas ou, ainda, nos casos
                indicados nas leis e regulamentos aplicáveis, nestes Termos de
                Uso ou em qualquer Política.
              </li>
            </ol>
          </li>

          <li>
            <h3>PAGAMENTO PELO USO DA PLATAFORMA</h3>
            <ol>
              <li>
                Os Usuários deverão contratar os serviços do Wedash, realizando
                o pagamento referente ao plano que escolher, os valores estarão
                sempre expostos antes da contratação. A cobrança ocorre no
                formato de assinatura, podendo ser mensal ou anual. O plano será
                renovado automaticamente ao chegar ao fim do período contratado
                e o Usuário poderá cancelar o plano a qualquer momento antes da
                próxima cobrança.
                <ol>
                  <li>
                    Respeitamos todos os seus direitos, inclusive o direito de
                    arrependimento e cancelamento do serviço com reembolso no
                    total dos valores pagos, no prazo de até 07 (sete) dias,
                    contados a partir da data da compra.
                  </li>
                  <li>
                    Após o prazo de 07 (sete) dias, caso o Usuário queira
                    cancelar os serviços do <strong>Wedash</strong>, será
                    cobrada uma multa de 8% (oito por cento) sobre o valor pago
                    e as devoluções ocorrerão da mesma forma que o pagamento foi
                    realizado.
                  </li>
                </ol>
              </li>

              <li>
                Cancelamento dos Serviços do Wedash
                <ol>
                  <li>
                    Para cancelar os serviços da plataforma o Usuário deverá
                    encaminhar a solicitação de cancelamento para o e-mail
                    <a href="mailto:contato@wedash.digital">
                      contato@wedash.digital
                    </a>
                    .
                  </li>
                  <li>
                    Para saber o que fazemos com os dados pessoais tratados
                    durante a jornada de uso da plataforma, acesse nossas
                    Políticas de Privacidade.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <h3>RESPONSABILIDADE DAS PARTES</h3>

            <ol>
              <li>
                <strong>Responsabilidade do Wedash:</strong>

                <ol>
                  <li>
                    Realizar os serviços conforme o descrito nestes termos de
                    uso;
                  </li>
                  <li>
                    Responsabilizar-se pelo funcionamento da plataforma e pelas
                    correções que eventualmente sejam necessárias;
                  </li>
                  <li>
                    Informar qualquer alteração dos serviços aos Usuários, por
                    meio de comunicados simples na plataforma.
                  </li>
                  <li>
                    Manter sempre operacional o sistema, observando os
                    parâmetros mínimos de SLA (Service Level Agreement),
                    mantendo a manutenção da plataforma em níveis não inferiores
                    a 90% (noventa por cento), salvo em hipóteses de caso
                    fortuito ou força maior.
                  </li>
                </ol>
              </li>

              <li>
                <strong>Responsabilidade dos Usuários:</strong>

                <ol>
                  <li>
                    Utilizar a plataforma conforme os critérios de utilização
                    definidos pelo <strong>Wedash</strong>, sem alterar a sua
                    programação, quebrar senhas ou realizar procedimentos que
                    venham causar prejuízos ao <strong>Wedash</strong> e aos
                    demais Usuários;
                  </li>
                  <li>
                    Responsabilizar-se para todos os efeitos, inclusive
                    jurídicos, pelo teor das informações que introduzir e pelos
                    compromissos que assumir na plataforma;
                  </li>
                  <li>
                    Respeitar integralmente estes Termos de Uso, Políticas de
                    Privacidade, legislação vigente e contratos entre as partes.
                  </li>
                  <li>
                    Realizar backups periódicos dos dados inseridos na
                    plataforma.
                  </li>
                  <li>
                    Responder integralmente pelos atos praticados e pelas
                    informações postadas na plataforma por seus prepostos,
                    usuários ou funcionários, que acarretem prejuízo à BOWE ou a
                    terceiro.
                  </li>
                  <li>
                    Jamais formalizar qualquer tipo de negócio jurídico em nome
                    da BOWE sem expressa autorização em documento específico
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <h3>ISENÇÃO DE RESPONSABILIDADE DO WEDASH:</h3>

            <ol>
              <li>
                <strong>O Wedash não se responsabiliza por:</strong>

                <ol>
                  <li>
                    Eventual indisponibilidade da plataforma, a qual não tenha
                    dado causa.
                  </li>
                  <li>
                    Condutas dos Usuários e pelo descumprimento de suas
                    obrigações.
                  </li>
                  <li>Inserção de informações falsas pelos Usuários.</li>
                  <li>
                    Danos que o Usuário possa experimentar por ações exclusivas
                    de terceiros, bem como falhas na conexão de rede e
                    interações maliciosas como vírus.
                  </li>
                  <li>
                    Danos que o Usuário possa ter em decorrência do mau uso da
                    plataforma em desconformidade com estes Termos, com a
                    Política de Privacidade, com a lei ou ordens judiciais.
                  </li>
                  <li>Casos fortuitos ou de força maior.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <h3>REGRAS DE CONDUTA E PROIBIÇÕES</h3>
            <ol>
              <li>
                Os Usuários não podem:
                <ol>
                  <li>
                    Lesar direitos do Wedash, dos operadores da plataforma, de
                    outros Usuários, de terceiros ou agir sob qualquer meio ou
                    forma que possa contribuir com tal violação;
                  </li>
                  <li>
                    Executar atos que limitem ou impeçam a utilização da
                    plataforma ou acessar ilicitamente o Wedash;
                  </li>
                  <li>
                    Utilizar a ferramenta para praticar ações ilícitas e
                    difundir mensagens não relacionadas com a plataforma ou com
                    suas finalidades, incluindo mensagens com conteúdo
                    impróprio;
                  </li>
                  <li>
                    Inserir dados que sejam falsos, desatualizados ou
                    incompletos;
                  </li>

                  <li>
                    Responsabilizar o Wedash por condutas de Usuários que
                    estejam cadastrados na plataforma.
                  </li>
                </ol>
              </li>

              <li>
                Ao sinal de qualquer descumprimento de conduta, o Usuário poderá
                ser bloqueado ou excluído da Plataforma, sem necessidade de
                aviso prévio.
              </li>
            </ol>
          </li>

          <li>
            <h3>PROPRIEDADE INTELECTUAL</h3>
            <ol>
              <li>
                A titularidade e os direitos relativos à plataforma pertencem
                exclusivamente a <strong>BOWE LTDA</strong>. O acesso à
                plataforma e a sua regular utilização pelo Usuário não lhe
                conferem qualquer direito ou prerrogativa sobre propriedade
                intelectual ou outro conteúdo nela inserido.
              </li>
              <li>
                Todo o conteúdo da plataforma, incluindo nome, marca, domínio,
                programas, bases de dados, arquivos, textos, desenhos, fotos,
                layouts, cabeçalhos e demais elementos, foi criado, desenvolvido
                e cedido a <strong>BOWE LTDA</strong>, sendo, portanto, de
                propriedade exclusiva da <strong>BOWE LTDA</strong> ou a ele
                licenciado e encontra-se protegido pelas leis brasileiras e
                tratados internacionais que versam sobre direitos de propriedade
                intelectual.
              </li>
              <li>
                São proibidas: a exploração, cessão, imitação, cópia, plágio,
                aplicação de engenharia reversa, tentativa de invasão (hackear),
                armazenamento, alteração, modificação de características,
                ampliação, venda, locação, doação, alienação, transferência,
                reprodução, integral ou parcial, de qualquer conteúdo da
                plataforma da <strong>BOWE LTDA.</strong>
              </li>
              <li>
                A pessoa que violar as proibições contidas na legislação sobre
                propriedade intelectual e nestes Termos será responsabilizada,
                civil e criminalmente, pelas infrações cometidas, além de poder
                ser penalizado na plataforma.
              </li>
            </ol>
          </li>

          <li>
            <h3>TRATAMENTO DE DADOS PESSOAIS, PRIVACIDADE E SEGURANÇA</h3>
            <ol>
              <li>
                O <strong>Wedash</strong> dispõe de uma política específica para
                regular a coleta, guarda e tratamento de dados pessoais, bem
                como a sua segurança: Políticas de Privacidade. Essas políticas
                específicas integram inseparavelmente estes Termos,
                ressaltando-se que os dados de utilização da plataforma serão
                arquivados nos termos da legislação em vigor.
              </li>
            </ol>
          </li>

          <li>
            <h3>DESDOBRAMENTOS DO ACESSO À PLATAFORMA</h3>
            <ol>
              <li>
                Apesar dos melhores esforços do <strong>Wedash</strong> para
                fornecer as melhores tecnologias para manter a conexão e
                sincronização online e acesso seguro aos Usuários, em virtude de
                dificuldades técnicas, aplicações de internet ou problemas de
                transmissão, é possível ocorrer cópias inexatas ou incompletas
                das informações contidas na plataforma. Além disso, vírus de
                computador ou outros programas danosos também poderão ser
                baixados inadvertidamente da plataforma.
                <ol>
                  <li>
                    O <strong>Wedash</strong> recomenda a instalação de
                    antivírus ou protetores adequados.
                  </li>
                </ol>
              </li>

              <li>
                O Wedash se reserva o direito de unilateralmente modificar a
                plataforma, bem como a configuração, a apresentação, o desenho,
                o conteúdo, as funcionalidades, as ferramentas ou qualquer outro
                elemento, inclusive o seu cancelamento.
              </li>
            </ol>
          </li>

          <li>
            <h3>ALTERAÇÕES DOS TERMOS E CONDIÇÕES DE USO</h3>

            <ol>
              <li>
                O <strong>Wedash</strong> poderá unilateralmente adicionar e/ou
                modificar qualquer cláusula contida nestes Termos de Uso. A
                versão atualizada valerá para o uso da plataforma realizada a
                partir de sua publicação. A continuidade de acesso ou utilização
                da plataforma, depois da divulgação, confirmará a vigência dos
                novos Termos de Uso pelos Usuários.
              </li>

              <li>
                Caso a mudança efetuada necessite de consentimento do Usuário,
                será apresentada a opção de aceitar de forma livre, inequívoca e
                informada o novo texto ou recusá-lo.
              </li>

              <li>
                Caso o Usuário não esteja de acordo com a alteração, poderá não
                fornecer consentimento para atos específicos ou poderá rescindir
                totalmente seu vínculo com o <strong>Wedash</strong>. Essa
                rescisão não eximirá, no entanto, o Usuário de cumprir com todas
                as obrigações assumidas sob as versões precedentes dos Termos de
                Uso.
              </li>
            </ol>
          </li>

          <li>
            <h3>LEI APLICÁVEL E FORO DE ELEIÇÃO</h3>

            <ol>
              <li>
                A plataforma é controlada, operada e administrada na cidade de
                Uberlândia, Estado de Minas Gerais, podendo ser acessada por
                qualquer dispositivo conectado à Internet, independentemente de
                sua localização geográfica.
                <ol>
                  <li>
                    O <strong>Wedash</strong> não garante que a plataforma seja
                    apropriada ou esteja disponível para uso em outros locais.
                    As pessoas que acessam ou usam o <strong>Wedash</strong> a
                    partir de outras jurisdições o fazem por conta própria e são
                    responsáveis pelo cumprimento das leis regionais/nacionais.
                  </li>
                </ol>
              </li>

              <li>
                O Usuário concorda que a legislação aplicável para fins destes
                Termos e Condições de Uso e das Políticas de Privacidade será a
                vigente na República Federativa do Brasil.
              </li>

              <li>
                O <strong>Wedash</strong> e o Usuário concordam que o Foro de
                Uberlândia/MG, Brasil, será o único competente para dirimir
                qualquer questão ou controvérsia oriunda ou resultante do uso da
                plataforma, renunciando expressamente a qualquer outro, por mais
                privilegiado que seja, ou venha a ser.
              </li>
            </ol>
          </li>

          <li>
            <h3>CONTATO</h3>
            <ol>
              <li>
                O <strong>Wedash</strong> disponibiliza o seguinte canal para
                receber todas as comunicações que os Usuários desejarem fazer
                por meio do e-mail{' '}
                <a href="mailto:contato@wedash.digital">
                  contato@wedash.digital
                </a>
                .
              </li>
            </ol>
          </li>
        </ol>
      </S.Container>
      <Footer
        logoSrc={WhiteWeDash}
        logoAlt="Logo da empresa em duas linhas alinhadas a esquerda, na primeira linha está escrito we.dash e na segunda linha está escrito by bowe"
        logoScroll="header"
      />
    </>
  );
};

export default Terms;
